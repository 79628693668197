/* You can add global styles to this file, and also import other style files */

.top-buffer-10 {
  margin-top: 10px;
}


@media print {
  .no-print {
    display: none;
  }

  .modal, .modal-content, .modal-backdrop {
    display: none !important;
  }


  #printable {
    margin: auto;
    margin-top: 40px;
    width: 400px;
    z-index: 1099;
  }
}

@media screen {
  #printable {
    display: none;
  }
}

.clickable {
  cursor: pointer;
}


.list-container {
}
